// import React from "react";
// import HomeImage from "../../Assets/Images/Home.jpeg";
// import "./Header.css"; // Import CSS file for custom styles if needed
// import TicketBox from "./TicketBox";
// import Nav from "../NavBar/Nav";

// const Header = () => {
//   return (
//     <>
//       <Nav />
//       {/* <NewNav/> */}
//       <div
//         className="container-fluid p-0  d-flex align-items-center justify-content-center bg-main"
//         style={{ backgroundImage: `url(${HomeImage})` , backgroundRepeat:"no-repeat"   }}
//       >
//         <div className="row text-center">
//           <div className="col-12 pt-5 mt-5">
//           <h1 style={{ color: "#FFFFFF" }}>
//             Your adventure travel expert in Iceland
//           </h1>
//           <div style={{ color: "#FFFFFF" }}>
//             <p>
//               With so much to see and do in Iceland, it can be hard to choose an
//               activity. Our diverse selection includes
//             </p>
//             <p>
//               hiking, caving, glacier walks, mountain climbing, hunting for the
//               Northern Lights and more!
//             </p>

//             <TicketBox />
//           </div>
//           </div>
         
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header;


import React from "react";
import HomeImage from "../../Assets/Images/Home.jpeg";
import "./Header.css"; // Import CSS file for custom styles if needed
import TicketBox from "./TicketBox";
import Nav from "../NavBar/Nav";

const Header = () => {
  return (
    <>
      <Nav />
      {/* <NewNav/> */}
      <div
        className="container-fluid p-0 bg-main"
        style={{ backgroundImage: `url(${HomeImage})`, backgroundRepeat: "no-repeat" }}
      >
        <div className="row text-center">
          <div className="col-12 text-container">
            <h1>Your adventure travel expert in Iceland</h1>
            <div>
              <p>With so much to see and do in Iceland, it can be hard to choose an activity. Our diverse selection includes</p>
              <p>hiking, caving, glacier walks, mountain climbing, hunting for the Northern Lights and more!</p>
              <TicketBox />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;





// import React from "react";
// import HomeImage from "../../Assets/Images/Home.jpeg";
// import "./Header.css"; // Import CSS file for custom styles if needed
// import TicketBox from "./TicketBox";
// import Nav from "../NavBar/Nav";

// const Header = () => {
//   return (
//     <>
//       <Nav />
//       <div
//         className="container-fluid p-0 bg-main"
//         style={{ backgroundImage: `url(${HomeImage})`, backgroundRepeat: "no-repeat" }}
//       >
//         <div className="row text-center">
//           <div className="col-12 text-container">
//             <h1>Your adventure travel expert in Iceland</h1>
//             <div>
//               <p>With so much to see and do in Iceland, it can be hard to choose an activity. Our diverse selection includes</p>
//               <p>hiking, caving, glacier walks, mountain climbing, hunting for the Northern Lights and more!</p>
//               <TicketBox />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Header;
