import React from "react";
import newYork from "../../Assets/Images/NewYork.svg";
import London from "../../Assets/Images/london.svg";
import Beijing from "../../Assets/Images/beijing.svg";
import Moscow from "../../Assets/Images/Moscow.svg";
import Shanghai from "../../Assets/Images/shanghai.svg";
import dubai from "../../Assets/Images/dubai.svg";
import { FaLocationDot, FaLocationPin } from "react-icons/fa6";

const PerfectTrip = () => {
  
  const dummyData = [
    { id: 1, name: "New York", imageUrl: newYork },
    { id: 2, name: "Paris", imageUrl: London },
    { id: 3, name: "Tokyo", imageUrl: Beijing },
    { id: 4, name: "London", imageUrl: Moscow },
    { id: 5, name: "Rome", imageUrl: Shanghai },
    { id: 6, name: "Sydney", imageUrl: dubai },
    // Add more destination data as needed
  ];

  // Function to chunk the data into groups of three
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  return (
    <>
      <div className="container mt-3">
        <div className="d-flex justify-content-between align-items-center mt-5">
          <div>
            <h2>Plan Your Perfect Trip</h2>
            <p>Lorem ipsum hen ajfi huie hfhu ajsj eis ejif fjij haye gure</p>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              See More Places
            </button>
          </div>
        </div>

        <div className="my-4">
          {chunkArray(dummyData, 3).map((chunk, index) => (
            <div key={index} className="row">
              {chunk.map((destination) => (
                <div key={destination.id} className="col-md-4 mb-3 ps-0">
                  <div
                    style={{
                      borderRadius: "10px",
                      overflow: "hidden",
                      border: "1px solid #dee2e6", // Border color
                      boxShadow: "10px 10px 40px 0px #0000001A",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src={destination.imageUrl}
                      alt={destination.name}
                      style={{
                        width: "114px",
                        height: "125px",
                        objectFit: "cover",
                      }}
                    />
                    <div  className="d-flex align-items-center" style={{ padding: "10px" }}>
                    <div>
                      <h4><small style={{color:"#1890FF"}} > <FaLocationDot/> </small> <span style={{color:"#112211"}}   >{destination.name} 4500</span></h4>
                      <div>
                        <ul className="m-0"
                          style={{
                            display: "flex",
                           
                            padding: 0,
                          }}
                        >
                          <li style={{ marginRight: "20px",listStyle:"none" }}>Flight</li>
                         
                          <li style={{ marginRight: "20px" }}>Hotel</li>
                          <li>Resorts</li>
                        </ul>
                    </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PerfectTrip;