import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { FaIndianRupeeSign, FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const PopularDestination = () => {
  const [destinations, setDestinations] = useState([]);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.gocapitalz.com/api/PopularHotels"); // Replace with your API URL
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (result.status === "true") {
          console.log(result);
          setDestinations(result.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to chunk the data into groups of four
  const chunkArray = (array, size) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArray.push(array.slice(i, i + size));
    }
    return chunkedArray;
  };

  // Function to handle card click and save ID in sessionStorage
  const handleCardClick = (id) => {
    sessionStorage.setItem("selectedDestinationId", id); // Save the ID in sessionStorage
    navigate(`/DetailTopDestination`); // Navigate to the detail page with the selected destination ID
  };

  return (
    <>
      <div className="container mt-3">
        {/* <div className="d-flex justify-content-between align-items-center mt-5">
          <div>
            <h2>Popular Destinations</h2>
            <p>Lorem ipsum hen ajfi huie hfhu ajsj eis ejif fjij</p>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: "5px",
              }}
              onClick={() => navigate('/PlanyourPerfectTrip')} // Redirect to '/all-destinations' on button click
            >
              See All
            </button>
          </div>
        </div> */}

        <div className="row mt-4 ps-0">
          <div className="col ps-0">
            <Carousel interval={null} style={{ marginRight: 0 }} loop>
              {/* Map through chunked destination data to generate Carousel.Item components */}
              {chunkArray(destinations, 4).map((chunk, index) => (
                <Carousel.Item key={index}>
                  <div className="row">
                    {chunk.map((destination) => (
                      <div
                        key={destination.Id}
                        className="col-md-3 mb-3 ps-0"
                        onClick={() => handleCardClick(destination.Id)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            borderRadius: "10px",
                            overflow: "hidden",
                            border: "1px solid #dee2e6", // Border color
                            // boxShadow: "10px 10px 40px 0px #0000001A",
                          }}
                        >
                          <img
                            src={destination.Image}
                            alt={destination.Title}
                            style={{
                              width: "100%",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <div className="overlay-text" style={{ margin: "15px" }}>
                          <small style={{color:"#1890FF"}} > <FaLocationDot/><span style={{color:'#333333'}}> {destination.Location}</span></small>
                            <h4>{destination.Title}</h4>
                            <small style={{ color: "#FF8682"}}>
                              <span style={{ color: 'black' }}><FaIndianRupeeSign /></span>
                              {destination.Price}/night
                            </small>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularDestination;
