import React, { useEffect, useState } from "react";
import face from "../../Assets/Images/face.svg";

import "./Testimonials.css";

const Testimonial = () => {
  const [startItem, setStartItem] = useState(1);
  const itemCount = 6; // Number of items in the carousel

  const dummyData = [
    {
      id: 1,
      name: "Anchul Rathi",
      imageUrl: face,
      message:
        "Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
      oneliner: "Hey I am the Best Backend Developer",
    },
    {
      id: 2,
      name: "Amlol Mishra",
      imageUrl: face,
      message: "Believe you can and you're halfway there. - Theodore Roosevelt",
      oneliner: "Best Employeee having best knowledge",
    },
    {
      id: 3,
      name: "Ambhichek Tiwari",
      imageUrl: face,
      message:
        "In the middle of every difficulty lies opportunity. - Albert Einstein",
      oneliner: "Hey I am the best at eating Chole Bhature",
    },
    {
      id: 4,
      name: "Ulmi Pipli",
      imageUrl: face,
      message:
        "You are never too old to set another goal or to dream a new dream.",
      oneliner: "I am Worst in everything...",
    },
    {
      id: 5,
      name: "Amditi Bhomtt",
      imageUrl: face,
      message:
        "You are never too old to set another goal or to dream a new dream.",
      oneliner: "Hey I am the best HR",
    },
    {
      id: 6,
      name: "Mallu",
      imageUrl: face,
      message:
        "You are never too old to set another goal or to dream a new dream.",
      oneliner: "Hey I am the best UI/UX Dev ",
    },
  ];

  useEffect(() => {
    const autoSwap = setInterval(() => swap(), 5000);

    const handleMouseEnter = () => clearInterval(autoSwap);
    const handleMouseLeave = () => setInterval(() => swap(), 7000);

    const carouselElement = document.querySelector(".carousel");
    if (carouselElement) {
      carouselElement.addEventListener("mouseenter", handleMouseEnter);
      carouselElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      clearInterval(autoSwap);
      if (carouselElement) {
        carouselElement.removeEventListener("mouseenter", handleMouseEnter);
        carouselElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [startItem]);

  const swap = () => {
    let newStartItem = startItem + 1;
    if (newStartItem > itemCount) {
      newStartItem = 1;
    }
    setStartItem(newStartItem);
  };

  return (
    <>
      <h2 className="review-title text-center">Testimonials</h2>
       {/* Testimonials Carousel */}
      <div className="container d-flex justify-content-center align-items-center">
        <div className="testimonial-container ">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <section className="carousel-wrap">
                    <ul className="carousel">
                      {dummyData.map((testimonial) => (
                        <li
                          key={testimonial.id}
                          className={`items ${getPositionClass(
                            testimonial.id,
                            startItem,
                            itemCount
                          )}`}
                        >
                          <div class="large-6 columns testimonial">
                            <div class="quote">
                              <p>{testimonial.message}</p>
                            </div>
                            <div className="student">
                              <img
                                src={testimonial.imageUrl}
                                alt={testimonial.name}
                                className="review-card-img"
                              />
                              <div>
                                <span className="ra-author">
                                  {testimonial.name}
                                </span>
                                <p style={{ color: "#777" }}>
                                  {testimonial.oneliner}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const getPositionClass = (id, startItem, itemCount) => {
  if (id === startItem) return "main-pos";
  if (id === startItem - 1 || (startItem === 1 && id === itemCount))
    return "left-pos";
  if (id === startItem + 1 || (startItem === itemCount && id === 1))
    return "right-pos";
  return "back-pos";
};

export default Testimonial;
