// import React, { useState, useEffect } from "react";
// import NewNav from "../NavBar/NewNav";
// import Footer from "../Footer/Footer";
// import newYork from "../../Assets/Images/NewYork.svg";

// const MainTopDestinations = () => {
//   const [destinations, setDestinations] = useState([]);

//   useEffect(() => {
//     // Function to fetch data from API
//     const fetchData = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}AllDestination`); // Replace with your API endpoint
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
//         const result = await response.json();
//         if (result.status === "true") {
//           setDestinations(result.data);
//         } else {
//           console.error("Failed to fetch destinations: ", result);
//         }
//       } catch (error) {
//         console.error("Error fetching destinations:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <>
//       <NewNav />
//       <div className="container">
//       <div className="d-flex justify-content-center"> {/* Centering container */}
//           <div
//             className="row mt-5 mx-5 p-3" // added margin and padding
//             style={{
//               backgroundColor: "black",
//               color: "white",
//               border: "2px solid black",
//               borderRadius: "20px",
//               width: "100%",
//               maxWidth: "1200px", // Added max-width for better centering
//             }}
//           >
//             <div className="text-center">
//               <h3 className="text-start">Top Destinations</h3>
//               <p className="text-start">
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
//                 sed arcu iaculis, tempus orci in, placerat ex.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="container mt-5">
//           <div className="row row-cols-1 row-cols-md-4 g-4">
//             {destinations.map((destination) => (
//               <div className="col" key={destination.Id}>
//                 <div
//                   className="mb-4" // added margin-bottom
//                   style={{
//                     position: "relative",
//                     width: "100%",
//                     height: "400px",
//                     borderRadius: "10px",
//                     overflow: "hidden",
//                   }}
//                 >
//                   <img
//                     src={destination.Image || newYork}
//                     alt={destination.Title}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       objectFit: "cover",
//                     }}
//                   />
//                   <div
//                     className="overlay-text"
//                     style={{
//                       position: "absolute",
//                       bottom: 0,
//                       left: 0,
//                       right: 0,
//                       padding: "20px",
//                       backgroundColor: "rgba(0, 0, 0, 0.5)",
//                       color: "white",
//                     }}
//                   >
//                     <h4>{destination.Title} ${destination.Price}</h4>
//                     <p>{destination.Description}</p>
//                     <br />
//                     <button
//                       style={{
//                         backgroundColor: "rgba(255, 255, 255, 0.25)", // RGBA color with 25% opacity
//                         border: "1px solid white",
//                         borderRadius: "10px",
//                         color: "white",
//                         padding: "5px 10px",
//                         display: "block",
//                         margin: "0 auto",
//                         width: "250px", // Set the width to 250px
//                       }}
//                     >
//                       Know More
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default MainTopDestinations;



import React, { useState, useEffect } from "react";
// import NewNav from "../NavBar/NewNav";
import Footer from "../Footer/Footer";
import newYork from "../../Assets/Images/NewYork.svg";
import { useNavigate } from "react-router-dom";
import Nav from "../NavBar/Nav";
import NewNav from "../NavBar/NewNav";


const MainTopDestinations = () => {
  const [destinations, setDestinations] = useState([]);

  useEffect(() => {
    // Function to fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}AllDestination`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        if (result.status === "true") {
          setDestinations(result.data);
        } else {
          console.error("Failed to fetch destinations: ", result);
        }
      } catch (error) {
        console.error("Error fetching destinations:", error);
      }
    };

    fetchData();
  }, []);
  const navigate = useNavigate();
  // Function to handle click on a card/block
  const handleClick = (id) => {
    sessionStorage.setItem("selectedDestinationId", id); // Save the clicked ID in sessionStorage
    navigate("/DetailTopDestination");
  };

  return (
    <>
      {/* <Nav/>
       */}
       <NewNav/>
      <div className="container">
        <div className="d-flex justify-content-center"> {/* Centering container */}
          <div
            className="row mt-5 mx-5 p-3" // added margin and padding
            style={{
              backgroundColor: "black",
              color: "white",
              border: "2px solid black",
              borderRadius: "20px",
              width: "100%",
              maxWidth: "1200px", // Added max-width for better centering
            }}
          >
            <div className="text-center">
              <h3 className="text-start">Top Destinations</h3>
              <p className="text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                sed arcu iaculis, tempus orci in, placerat ex.
              </p>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row row-cols-1 row-cols-md-4 g-4">
            {destinations.map((destination) => (
              <div className="col" key={destination.Id}>
                <div
                  className="mb-4" // added margin-bottom
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "400px",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                  onClick={() => handleClick(destination.Id)} // Add onClick event handler
                >
                  <img
                    src={destination.Image || newYork}
                    alt={destination.Title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="overlay-text"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                    }}
                  >
                    <h4>{destination.Title} ${destination.Price}</h4>
                    <p>{destination.Description}</p>
                    <br />
                    <button
                      style={{
                        backgroundColor: "rgba(255, 255, 255, 0.25)", // RGBA color with 25% opacity
                        border: "1px solid white",
                        borderRadius: "10px",
                        color: "white",
                        padding: "5px 10px",
                        display: "block",
                        margin: "0 auto",
                        width: "250px", // Set the width to 250px
                      }}
                    >
                      Know More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MainTopDestinations;
