import React from "react";
import Header from "../Header/Header";
import TopDestinations from "./TopDestinations";
import PopularDestination from "./PopularDestination";
import PerfectTrip from "./PerfectTrip";
import Blogs from "./Blogs";
import Footer from "../Footer/Footer";
import { Navigate, useNavigate } from "react-router-dom/dist";
import Testimonials from "../Testimonials/Testimonials";
const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <TopDestinations/>
      <div className="container" >
      <div className="d-flex justify-content-between align-items-center mt-5">
          <div>
            <h2>Popular Destinations</h2>
            <p>Lorem ipsum hen ajfi huie hfhu ajsj eis ejif fjij</p>
          </div>
          <div>
            <button
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: "5px",
              }}
              onClick={() => navigate('/PlanyourPerfectTrip')} // Redirect to '/all-destinations' on button click
            >
              See All
            </button>
          </div>
        </div>
      </div>
     
      
      <PopularDestination/>
      <PerfectTrip/>
      <Testimonials/>
      {/* <Blogs/> */}
      <Footer/>
    </>
  );
};

export default Home;
