// import React, { useState } from "react";
// import LeftImage from "../../Assets/Images/Auth.svg";
// import { Link } from "react-router-dom";
// import Logo from "../../Assets/Images/Group.svg";

// function VerifyOtp() {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });

//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Your form submission logic goes here
//     console.log("Form submitted:", formData);
//   };

//   return (
//     <>
//       <div className="container-fluid" style={{ overflow: "hidden" }}>
//         <div className="row">
//           <div className="col-lg-6 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover",
//                 height: "100vh",
//               }}
//             ></div>
//           </div>

//           {/* LogIn Body */}

//           <div
//             className="col-lg-6 "
//             style={{ backgroundColor: "white", height: "100vh" }}
//           >
//             {/* putting Logo */}
//             <div>
//               <img src={Logo} alt="Logo" />
//             </div>

//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2"
//                 style={{
//                   maxHeight: "550px",
//                   borderRadius: "20px",
//                   border: "none",
//                 }}
//               >
//                 <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
//                   <h2
//                     className="card-title justify-content-center mb-4"
//                     style={{ color: "black" }}
//                   >
//                     Verify Code
//                   </h2>
//                   <h6>
//                   An authentication code has been sent to your email.
//                   </h6>

//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <input
//                         type="email"
//                         name="email"
//                         style={{ width: "100%" }}
//                         placeholder="Enter Code "
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="form-control"
//                         required
//                       />
//                     </div>
//                     <p className="text-center mt-3 mb-0">
//                 Didn’t receive a code? <Link to="/resend">Resend</Link>
//               </p>

//                     <div className="text-center mt-3">
//                     <button
//                   type="submit"
//                   className="btn mt-1"
//                   style={{
//                     borderRadius: "10px",
//                     backgroundColor: "black",
//                     color: "white",
//                     width: "100%", // Set the width to 100%
//                   }}
//                 >
//                   Verify
//                 </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default VerifyOtp;

import React, { useState } from "react";
import LeftImage from "../../Assets/Images/Auth.svg";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/Group.svg";
import { useNavigate } from 'react-router-dom';


function VerifyOtp() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  
  const navigate = useNavigate();
  
  //  const userValidate = sessionStorage.getItem("UserValid");
  //  console.log("userValid",userValidate);

  const handleChange = (e) => {
    setOtp(e.target.value);
  };
  const token = sessionStorage.getItem("token");
 console.log(token);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      OTP: otp, // Ensure the field is named "otp"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}OTPVerification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
      

      const data = await response.json();
      console.log("data-", data);

      if (response.ok) {
        setSuccess('OTP verified successfully');
        setError('');
        navigate('/'); 
      } else {
        setError(data.errors || 'OTP verification failed');
        setSuccess('');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError('An error occurred while verifying OTP');
      setSuccess('');
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100"
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </div>

          <div
            className="col-lg-6 "
            style={{ backgroundColor: "white", height: "100vh" }}
          >
            <div>
              <img src={Logo} alt="Logo" />
            </div>

            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2"
                style={{
                  maxHeight: "550px",
                  borderRadius: "20px",
                  border: "none",
                }}
              >
                <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
                  <h2
                    className="card-title justify-content-center mb-4"
                    style={{ color: "black" }}
                  >
                    Verify Code
                  </h2>
                  <h6>
                    An authentication code has been sent to your email.
                  </h6>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="otp"
                        style={{ width: "100%" }}
                        placeholder="Enter Code"
                        value={otp}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    {error && (
                      <p className="text-danger">{error}</p>
                    )}
                    {success && (
                      <p className="text-success">{success}</p>
                    )}
                    <p className="text-center mt-3 mb-0">
                      Didn’t receive a code? <Link to="/resend">Resend</Link>
                    </p>

                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "black",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOtp;
