import React from "react";
import Footer from "./Footer";
import Nav from "../NavBar/Nav";
import GetInTouchImage from "../../Assets/Images/GetTouch.svg"; // Import the image
import "./GetTouch.css";

const GetTouch = () => {
  return (
    <>
      <Nav />
      {/* Background Image */}
      <img
          src={GetInTouchImage}
          alt="Get In Touch"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
            opacity:"95%"
          }}
        />

      <div
        className="container"
        style={{
          position: "relative",
          height: "100vh", // Cover the viewport height
          overflow: "hidden", // Hide overflowing text
        }}
      >
        
        {/* Text Overlay */}
        <div
          className="row d-flex align-items-center "
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: "white", // Text color
            zIndex: 1,
          }}
        >
          <div className="col-lg-5 m-5">
            <h3>
              <b>Get In Touch</b>
            </h3>
            <p>
              You can mail us at cs@myexclusivedeals.com <br />
              Or, drop in a message below & we will get back to you in a jiffy.
            </p>

            <div>
      <form>
  <input
    style={{ width: "100%", marginBottom: "20px", padding: "10px", backgroundColor: "transparent", border: "1px solid #00000033", outline: "none", borderRadius: "10px", color: "white" }}
    placeholder="Enter Name"
  />
  <br />
  <input
    style={{ width: "100%", marginBottom: "20px", padding: "10px", backgroundColor: "transparent", borderRadius: "10px", border: "1px solid #00000033", outline: "none", color: "white" }}
    placeholder="Email"
  />
  <br />
  <input
    style={{ width: "100%", marginBottom: "20px", padding: "10px", backgroundColor: "transparent", borderRadius: "10px", border: "1px solid #00000033", outline: "none", color: "white" }}
    placeholder="Phone Number"
  />
  <br />
  <textarea
    style={{ width: "100%", height: "150px", marginBottom: "20px", padding: "10px", backgroundColor: "transparent", borderRadius: "10px", border: "1px solid #00000033", outline: "none", color: "white" }}
    placeholder="Message"
  />
  <br />
  <button
    style={{ width: "100%", padding: "10px", backgroundColor: "#000000", borderRadius: "10px", border: "none", color: "white", cursor: "pointer" }}
    type="submit"
  >
    Submit
  </button>
</form>

    </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default GetTouch;
