
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/Images/Logo.svg";

const Nav = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-transparent">
      <div className="container">
        <NavLink to="/" className="navbar-brand text-white">
          <img src={Logo} alt="logo" height="30px" width="110px" />
        </NavLink>
        <button className="navbar-toggler" type="button" onClick={handleToggle}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className={`collapse navbar-collapse ${isToggled ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto gap-2"> {/* Changed ml-auto to ms-auto for Bootstrap 5 */}
            <NavItem to="/" text="Home" onClick={handleToggle} />
            <NavItem to="/Activities" text="Activities" onClick={handleToggle} />
            <NavItem to="/contactUs" text="ContactUs" onClick={handleToggle} />
            <button style={{ backgroundColor: "black", borderRadius: "10px", borderColor: "white" }}>
              <NavItem to="/Login" text="LogIn" onClick={handleToggle} />
            </button>
            <button style={{ backgroundColor: "black", borderRadius: "10px", borderColor: "white" }}>
              <NavItem to="/Signup" text="SignUp" onClick={handleToggle} />
            </button>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const NavItem = ({ to, text, onClick }) => (
  <li className="nav-item">
    <NavLink to={to} exact className="nav-link text-white" activeClassName="active" onClick={onClick}>
      {text}
    </NavLink>
  </li>
);

export default Nav;
