// import React, { useState } from "react";
// import LeftImage from "../../../Assets/Images/Auth.svg";
// import { Link, useNavigate } from "react-router-dom";
// import Logo from "../../../Assets/Images/Group.svg";
// import { PacmanLoader } from "react-spinners";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function LogIn() {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });

//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError("");

//     const apiUrl = process.env.REACT_APP_API_BASE_URL;
//     const loginEndpoint = `${apiUrl}Login`;
     

    


//     const requestBody = {
//       Email: formData.email,
//       Password: formData.password,
//     };

//     try {
//       const response = await fetch(loginEndpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(requestBody),
//       });

//       const data = await response.json();
//       console.log("Login response:", data);

//       if (response.ok && data.status === 200) {
//         sessionStorage.setItem("token", data.token);
//         setLoading(false);
//         toast.success("Successfully logging in.."); // Show success message
//         navigate("/"); // Redirect to home page on successful login
//       } else {
//         throw new Error(data.message || "Failed to log in");
//       }
//     } catch (error) {
//       console.error("Error logging in:", error);
//       setLoading(false);
//       toast.error(error.message || "Failed to log in. Please try again.");
//       setFormData({ email: "", password: "" }); // Clear input fields on error
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="container-fluid" style={{ overflow: "hidden" }}>
//         <div className="row">
//           {/* Left Side */}
//           <div className="col-lg-6 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover",
//                 height: "100vh",
//               }}
//             ></div>
//           </div>

//           {/* Right Side */}
//           <div className="col-lg-6" style={{ backgroundColor: "white", height: "100vh" }}>
//             {/* Logo */}
//             <div>
//               <link><img src={Logo} alt="Logo"  /></link>
//             </div>

//             {/* Card Body */}
//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div className="card mx-2" style={{ border: "none" }}>
//                 <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
//                   <h2 className="card-title justify-content-center" style={{ color: "black" }}>
//                     Log In
//                   </h2>
//                   <p>Contrary to popular belief, Lorem Ipsum is not simply random text</p>

//                   <form onSubmit={handleSubmit} className="w-100">
//                     {/* Email input */}
//                     <div className="mb-3">
//                       <input
//                         type="email"
//                         name="email"
//                         placeholder="Email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="form-control"
//                         required
//                       />
//                     </div>
//                     {/* Password input */}
//                     <div className="mb-3">
//                       <div className="input-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           name="password"
//                           placeholder="Password"
//                           value={formData.password}
//                           onChange={handleChange}
//                           className="form-control"
//                           required
//                         />
//                         <button
//                           type="button"
//                           className="btn btn-outline-secondary"
//                           onClick={togglePasswordVisibility}
//                           style={{ color: "black" }}
//                         >
//                           {showPassword ? "Hide" : "Show"}
//                         </button>
//                       </div>
//                     </div>
//                     {/* Forget password link */}
//                     <div className="text-end">
//                       <Link
//                         to="/ForgetPassword"
//                         className="link-dark"
//                         style={{ textDecoration: "none", color: "#FF8682" }}
//                       >
//                         Forget Password?
//                       </Link>
//                     </div>
//                     {/* Loader component */}
//                     <div className="text-center mt-3">
//                       <PacmanLoader color="#36d7b7" size={40} loading={loading} />
//                     </div>
//                     {/* Error message */}
//                     {error && <p className="text-danger mt-3">{error}</p>}
//                     {/* Log in button */}
//                     <div className="text-center mt-3">
//                       <button
//                         type="submit"
//                         className="btn mt-1"
//                         style={{
//                           borderRadius: "10px",
//                           backgroundColor: "black",
//                           color: "white",
//                           width: "100%",
//                         }}
//                         disabled={loading} // Disable the button while loading
//                       >
//                         Log In
//                       </button>
//                     </div>
//                   </form>

//                   {/* Sign up link */}
//                   <p className="text-center mt-3 mb-3" style={{ color: "black" }}>
//                     Don't have an account?{" "}
//                     <span className="text-center m-0">
//                       <Link
//                         to="/SignUp"
//                         style={{
//                           textDecoration: "none",
//                           color: "#FF8682",
//                           fontWeight: "bold",
//                         }}
//                       >
//                         Sign Up
//                       </Link>
//                     </span>
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default LogIn;




import React, { useState } from "react";
import LeftImage from "../../../Assets/Images/Auth.svg";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/Group.svg";
import { PacmanLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LogIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const loginEndpoint = `${apiUrl}Login`;

    const requestBody = {
      Email: formData.email,
      Password: formData.password,
    };

    try {
      const response = await fetch(loginEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      console.log("Login response:", data);

      if (response.ok && data.status === 200) {
        sessionStorage.setItem("token", data.token);
        setLoading(false);
        toast.success("Successfully logging in..");
        navigate("/"); // Redirect to home page on successful login
      } else {
        throw new Error(data.message || "Failed to log in");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoading(false);
      toast.error(error.message || "Failed to log in. Please try again.");
      setFormData({ email: "", password: "" });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row">
          {/* Left Side */}
          <div className="col-lg-6 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100 "
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </div>

          {/* Right Side */}
          <div className="col-lg-6" style={{ backgroundColor: "white", height: "100vh" }}>
            {/* Logo */}
            <div className="py-2">
              <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>

            {/* Card Body */}
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div className="card mx-2" style={{ border: "none" }}>
                <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
                  <h2 className="card-title justify-content-center" style={{ color: "black" }}>
                    Log In
                  </h2>
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text</p>

                  <form onSubmit={handleSubmit} className="w-100">
                    {/* Email input */}
                    <div className="mb-3">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    {/* Password input */}
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                          style={{ color: "black" }}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
                    {/* Forget password link */}
                    <div className="text-end">
                      <Link
                        to="/ForgetPassword"
                        className="link-dark"
                        style={{ textDecoration: "none", color: "#FF8682" }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                    {/* Loader component */}
                    <div className="text-center mt-3">
                      <PacmanLoader color="#36d7b7" size={40} loading={loading} />
                    </div>
                    {/* Error message */}
                    {error && <p className="text-danger mt-3">{error}</p>}
                    {/* Log in button */}
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "black",
                          color: "white",
                          width: "100%",
                        }}
                        disabled={loading}
                      >
                        Log In
                      </button>
                    </div>
                  </form>

                  {/* Sign up link */}
                  <p className="text-center mt-3 mb-3" style={{ color: "black" }}>
                    Don't have an account?{" "}
                    <span className="text-center m-0">
                      <Link
                        to="/SignUp"
                        style={{
                          textDecoration: "none",
                          color: "#FF8682",
                          fontWeight: "bold",
                        }}
                      >
                        Sign Up
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogIn;
