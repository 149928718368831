import React, { useState } from "react";
import LeftImage from "../../../Assets/Images/Auth.svg";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/Images/Group.svg";
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FadeLoader } from "react-spinners";

function SignUp() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailRegex.test(value);
    }

    if (name === "phone") {
      const phoneRegex = /^[0-9]+$/;
      isValid = phoneRegex.test(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "confirmPassword" || name === "password") {
      setPasswordsMatch(formData.password === value);
    }

    if (!isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: `Invalid ${name}`,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
  
    const requestBody = {
      Name: formData.name,
      Email: formData.email,
      Phone: formData.phone,
      Password: formData.password, // Change confirmPassword to Password
    };
  
    try {
      setLoading(true); // Set loading state to true on form submit
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}registration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
  
      const data = await response.json();
      console.log("data-", data);
      console.log("data-", data.status);
      if (data.status === false) {
        console.log("hdjfhdj");
        toast.error(data.message);
        setFormData({ // Clear the form if there is an error
          name: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
        });
        return;
      }
  
      if (data.status === 200) {
        sessionStorage.setItem("token", data.token);
        navigate('/verifyOtp'); // Redirect to verifyOtp page
      } else {
        toast.error(data.message); // Show error message using toastify
        setFormData({ // Clear the form if status is not 200
          name: "",
          email: "",
          phone: "",
          password: "",
          confirmPassword: "",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form"); // Show error message using toastify
    } finally {
      setLoading(false); // Set loading state to false after form submission
    }
  };
  

  return (
    <>
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100 "
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover",
                height: "100vh",
                
              }}
            ></div>
          </div>

          {/* SignUp Body */}

          <div
            className="col-lg-6"
            style={{ backgroundColor: "white", height: "100vh" }}
          >
            {/* putting Logo */}
            <div className="mt-3" >
            <Link to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>

            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2"
                style={{
                  maxHeight: "550px",
                  borderRadius: "20px",
                  border: "none",
                }}
              >
                <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
                  <h2
                    className="card-title justify-content-center mb-4"
                    style={{ color: "black" }}
                  >
                    SIGN UP
                  </h2>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.name && (
                        <small className="text-danger">{errors.name}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                      {errors.phone && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                          style={{ color: "black" }}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{ color: "black" }}
                        >
                          {showConfirmPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                      {!passwordsMatch && (
                        <small className="text-danger">
                          Passwords do not match
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                      <p>
                        <input
                          type="checkbox"
                          name="agreed"
                          checked={true}
                          disabled
                          className="form-check-input"
                          style={{
                            backgroundColor: "black", 
                            color: "white", 
                          }}
                        />
                        &nbsp;I agree to the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#FF8682",
                            fontWeight: "bold",
                          }}
                        >
                          Terms & Condition
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/privacy"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#FF8682",
                            fontWeight: "bold",
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </div>
                    <div className="text-center">
                      {/* Conditional rendering of FadeLoader based on loading state */}
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "black",
                          color: "white",
                          width: "100%", // Set the width to 100%
                        }}
                      >
                        {loading ? (
                          <FadeLoader
                            color="#36d7b7"
                            height={24}
                            margin={3}
                            radius={18}
                            speedMultiplier={4}
                            width={6}
                          />
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </div>
                  </form>
                  <p
                    className="text-center mt-3 mb-3"
                    style={{ color: "black" }}
                  >
                    Don't have an account?{" "}
                    <span className="text-center m-0">
                      <Link
                        to="/Login"
                        style={{
                          textDecoration: "none",
                          color: "#FF8682",
                          fontWeight: "bold",
                        }}
                      >
                        LogIn
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
