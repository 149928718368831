import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../Assets/Images/Logo.svg"; // Make sure the path is correct
import {
  faEnvelope,
  faPhone,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div
      className="container-fluid px-0 px-md-5"
      style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
    >
      <div className="row p-2 p-md-5">
        <div className="col-lg-8 pt-4 pe-4">
          <h3 className="mb-4 d-flex align-items-center">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "110px", height: "25px", marginRight: "10px" }}
            />
          </h3>
          <p className=" fs-14">
            We ara a lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat...
            <span>
              <br></br>
              <b>Read More</b>
            </span>
          </p>

          <div className="d-flex mt-2 flex-wrap">
            <div className="d-flex">
              <div>
                <h2>
                  <FontAwesomeIcon icon={faPhone} />
                </h2>
              </div>
              <p className="fs-14" style={{ marginLeft: "10px" }}>
                <b>Have a Question?</b>
                <br></br>
                <h6>
                  <b>Ph: (+91) 931 919 3103</b>
                </h6>
              </p>
            </div>

            <div className="d-flex ms-0 ms-md-3">
              <div>
                <h2>
                  <FontAwesomeIcon icon={faEnvelope} />
                </h2>
              </div>
              <p className="fs-14" style={{ marginLeft: "10px" }}>
                <b>Contact Us at:</b>
                <br></br>
                <h6>
                  <b>info@nomadzdigital.com</b>
                </h6>
              </p>
            </div>
          </div>
        </div>

        <div className="col-lg-4 pt-4">
          <div className="row">
            <div className="col-lg-12">
              <h5 className="fw-bold">News Letter</h5>

              <div>
                <h6>
                  Lorem ipsum hebig hejfuissd jjhf jreu jfsdj jfe jksejr jfneru
                  nus feruf jsnau nfre njre
                </h6>
                <br></br>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <input
                    type="text"
                    style={{
                      backgroundColor: "#000000",
                      color: "white",
                      padding: "10px",
                      paddingRight: "130px", // Ensure space for the button
                      borderRadius: "20px 14px 14px 20px",
                      border: "1px solid #ccc",
                    }}
                    placeholder="Enter your Email"
                  />
                  <button
                    type="submit"
                    style={{
                      position: "absolute",
                      top: 1,
                      right: 0.3,
                      padding: "10px 20px",
                      backgroundColor: "white",
                      color: "#000000",
                      borderRadius: "15px", // Match input field border radius
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <b>Submit</b>
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <ul
                  className="mt-4 fs-14 p-0"
                  style={{
                    listStyleType: "none",
                    paddingLeft: "0",
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <li className="mb-4">
                    <a
                      href="https://www.facebook.com"
                      style={{
                        color: "#FFFFFF",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{ color: "#ffffff", marginRight: "5px" }}
                      />
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.instagram.com"
                      style={{
                        color: "#FFFFFF",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ color: "#ffffff", marginRight: "5px" }}
                      />
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.twitter.com"
                      style={{
                        color: "#FFFFFF",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTwitter}
                        style={{ color: "#ffffff", marginRight: "5px" }}
                      />
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.linkedin.com"
                      style={{
                        color: "#FFFFFF",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "#ffffff", marginRight: "5px" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.5)" }}></div>
      <div className="row p-2">
        <div className="col-lg-8 ps-4 fs-14">
          <ul className="d-flex p-0 flex-wrap" style={{ listStyleType: "none", gap: "20px" }}>
            <li>
              <small>About Us</small>
            </li>
            <li>
  <a href="/contactUs" style={{ textDecoration: "none", color: "inherit" }}>
    <small>Contact Us</small>
  </a>
</li>

            <li>
              {" "}
              <small>Privacy Policy</small>
            </li>
            <li>
              <small>Terms of Use</small>
            </li>
          </ul>
        </div>

        <div className="col-lg-4 d-flex justify-content-start justify-content-md-start fs-14 ">
          <p>
            <small>© 2024, All Rights Reserved</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
