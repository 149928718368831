import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for styling toast notifications
import './App.css';
import Home from './Components/Home/Home';
import SignUp from "./Components/UserAuth/SignUp/SignUp";
import LogIn from "./Components/UserAuth/LogIn/LogIn";
import ForgetPassword from "./Components/UserAuth/ForgetPassword";
import VerifyOtp from "./Components/UserAuth/VerifyOtp";
import PasswordReset from "./Components/UserAuth/PasswordReset";
import GetTouch from "./Components/Footer/GetTouch";
import MainTopDestinations from "./Components/TopDestination/MainTopDestinations";
import VerifyOtpForget from "./Components/UserAuth/VerifyOtpForget";
import DetailTopDestination from "./Components/TopDestination/DetailTopDestination";
import PlanyourPerfectTrip from "./Components/PlanyourPerfectTrip";
import Activities from "./Components/Activities/Activities";
import ContactUs from "./Components/Contact/ContactUs";



function App() {
  return (
   <>
   <BrowserRouter>
   <ToastContainer autoClose={1000} />

    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/signup" element={<SignUp/>} />
      <Route path="/Login" element={<LogIn/>} />
      <Route path="/forgetPassword" element={<ForgetPassword/>} />
      <Route path="/verifyOtp" element={<VerifyOtp/>} />
      <Route path="/Passwordreset" element={<PasswordReset/>} />
      <Route path="/GetTouch" element={<GetTouch/>} />
      <Route path="/MainTopDestinations" element={<MainTopDestinations/>} />
      <Route path="/VerifyOtpForget" element={<VerifyOtpForget/>} />
      <Route path="/DetailTopDestination" element={<DetailTopDestination/>} />
      <Route path="/PlanyourPerfectTrip" element={<PlanyourPerfectTrip/>} />
      <Route path="/Activities" element={<Activities/>} />
      <Route path="/ContactUs" element={<ContactUs/>} />
    </Routes>
   </BrowserRouter>
   
   </>
  );
}

export default App;
