// import React, { useState, useEffect } from "react";
// import { NavLink, Link } from "react-router-dom";
// import Logo from "../../Assets/Images/Groupnew.svg";
// import "./NewNav.css";

// const NewNav = () => {
//   const [isToggled, setIsToggled] = useState(false);
//   const [scrolled, setScrolled] = useState(false);

//   const handleToggle = () => {
//     setIsToggled(!isToggled);
//   };

//   const handleScroll = () => {
//     const offset = window.scrollY;
//     if (offset > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <nav className={`navbar navbar-expand-lg fixed-top ${scrolled ? "scrolled" : ""} ${isToggled ? "navbar-bg-bright" : "navbar-transparent"}`}>
//       <div className="container">
//         <NavLink to="/" className="navbar-brand fw-bold text-white">
//           <img src={Logo} alt="logo" height="30px" width="110px" />
//         </NavLink>

//         <button
//           className="navbar-toggler"
//           type="button"
//           onClick={handleToggle}
//         >
//           =
//         </button>

//         <div className={`collapse navbar-collapse justify-content-end ${isToggled ? "show" : ""}`}>
//           <ul className="navbar-nav gap-4">
//             <NavItem to="/" text="Home" onClick={handleToggle} />
//             <NavItem to="/AllPackages" text="Activities" onClick={handleToggle} />
//             <NavItem to="/helpMe" text="Community Page" onClick={handleToggle} />
//             <NavItem to="/blogs" text="Blog" onClick={handleToggle} />
//             <li className="nav-item" style={{ cursor: "pointer", textDecoration: "none" }}>
//               <Link to="/Login" style={{ textDecoration: "none" }}>
//                 <button
//                   className="btn nav-link"
//                   onClick={handleToggle}
//                 >
//                   Log In
//                 </button>
//               </Link>
//             </li>
//             <Link to="/Signup" style={{ textDecoration: "none" }}>
//               <li className="nav-item" style={{ cursor: "pointer", textDecoration: "none" }}>
//                 <button
//                   className="btn nav-link"
//                   onClick={handleToggle}
//                 >
//                   Sign Up
//                 </button>
//               </li>
//             </Link>
//           </ul>
//         </div>
//       </div>
//     </nav>
//   );
// };

// const NavItem = ({ to, text, onClick }) => {
//   return (
//     <li className="nav-item">
//       <NavLink
//         to={to}
//         exact
//         className="nav-link"
//         activeClassName="active"
//         onClick={onClick}
//       >
//         {text}
//       </NavLink>
//     </li>
//   );
// };

// export default NewNav;
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/Images/Logo.svg";

const NewNav = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "black" }}>
      <div className="container">
        <NavLink to="/" className="navbar-brand text-white">
          <img src={Logo} alt="logo" height="30px" width="110px" />
        </NavLink>
        <button className="navbar-toggler text-white" type="button" onClick={handleToggle}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className={`collapse navbar-collapse ${isToggled ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto gap-2">
            <NavItem to="/" text="Home" onClick={handleToggle} />
            <NavItem to="/Activities" text="Activities" onClick={handleToggle} />
            <NavItem to="/contactUs" text="ContactUs" onClick={handleToggle} />
            <button style={{ backgroundColor: "black", borderRadius: "10px", borderColor: "white", color: "white" }}>
              <NavItem to="/Login" text="LogIn" onClick={handleToggle} />
            </button>
            <button style={{ backgroundColor: "black", borderRadius: "10px", borderColor: "white", color: "white" }}>
              <NavItem to="/Signup" text="SignUp" onClick={handleToggle} />
            </button>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const NavItem = ({ to, text, onClick }) => (
  <li className="nav-item">
    <NavLink to={to} exact className="nav-link text-white" activeClassName="active" onClick={onClick}>
      {text}
    </NavLink>
  </li>
);

export default NewNav;
