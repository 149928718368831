import React, { useState, useEffect } from 'react';
import NewNav from '../NavBar/NewNav';
import Footer from '../Footer/Footer';
import { FaIndianRupeeSign, FaLocationDot } from 'react-icons/fa6';
import superiorRoom from "../../Assets/Images/superiorRoom.svg";
import { FaShareAlt } from "react-icons/fa";
import Nav from '../NavBar/Nav';
import PopularDestination from '../Home/PopularDestination';

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const DetailTopDestination = () => {
  const [destinationData, setDestinationData] = useState(null);
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    const fetchDestinationData = async () => {
      try {
        const id = sessionStorage.getItem("selectedDestinationId");
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}DestinationDetail/${id}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch destination data: ${response.status}`);
        }
        const data = await response.json();
        console.log("=",data);
        setDestinationData(data.data);
        if (data.data.Facilities) {
          setFacilities(data.data.Facilities);
        }
      } catch (error) {
        console.error('Error fetching destination data:', error);
      }
    };

    fetchDestinationData();
  }, []);

  if (!destinationData) {
    return <div>Loading...</div>;
  }

  const renderImagesManually = () => (
    <div className="image-container" style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
      {destinationData.Images?.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          alt={`Destination ${index + 1}`}
          style={{ width: '100%', maxWidth: '400px', height: 'auto', objectFit: 'cover' }}
          onError={(e) => console.error('Error loading image:', e.target.src)}
        />
      ))}
    </div>
  );

  const chunkedFacilities = chunkArray(facilities, 4);

  return (
    <>
      <NewNav />
      
      <div className='container content-container mt-5'>
        <div className='col-lg-12 mt-2'>
          {destinationData.Images?.length === 1 ? (
            <div
              className="frame"
              style={{
                width: "100%",
                height: "550px",
                overflow: "hidden",
                borderRadius: "10px",
              }}
            >
              <img
                src={destinationData.Images[0]}
                alt="Destination"
                onError={(e) => console.error('Error loading image:', e.target.src)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ) : (
            renderImagesManually()
          )}
        </div>

        <hr />

        <div className='col-lg-12'>
          <div className='d-flex justify-content-between align-items-center'>
            <h2>{destinationData.Title}</h2>
            <div>
              <span style={{ fontWeight: 'bold', color: "#FF8682" }}>
                <FaIndianRupeeSign /> {destinationData.Price}
              </span>
              <small style={{ color: "#FF8682" }}>/night</small>
            </div>
          </div>
          <small>
            <span style={{ color: "Blue" }}>
              <FaLocationDot />
            </span>
            {destinationData.Location}
          </small>

          <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
              <div
                style={{
                  width: "50px",
                  border: "1px solid #FF8682",
                  margin: "10px",
                  borderRadius: "6px",
                  background: "#FF8682",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: 0.8
                }}
              >
                <p style={{ color: "white", margin: 0 }}>4.5</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <p style={{ margin: 0 }}>Very Good Reviews</p>
              </div>
            </div>
            <div>
  <button
    className="btn"
    style={{
      borderRadius: "10px",
      backgroundColor: "Black",
      color: "#FFFFFF",
      marginRight: "10px" // Add this line for spacing
    }}
  >
    <FaShareAlt />
  </button>
  <button
    className="btn"
    style={{
      borderRadius: "10px",
      backgroundColor: "Black",
      color: "#FFFFFF",
    }}
  >
    Book now
  </button>
</div>

          </div>

          <h2 className='mt-2'>Overview</h2>
          <p dangerouslySetInnerHTML={{ __html: destinationData.Overview || 'No overview available' }}></p>

          <hr />
        </div>

        <div>
          <h3 className='mt-2'>Popular Amenities</h3>
          <div className='row'>
            {chunkedFacilities.map((facilityChunk, chunkIndex) => (
              <div className='col-md-3' key={chunkIndex}>
                <ul>
                  {facilityChunk.map((facility, index) => (
                    <li key={index}>{facility}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <hr />

        <div className='col-lg-12'>
          <h3 className='mt-2 mb-3'>Available Rooms</h3>
          <div className='d-flex flex-wrap justify-content-between align-items-center'>
            <div className='d-flex align-items-center' style={{ flex: 1 }}>
              <img src={superiorRoom} alt='Superior Room' style={{ width: "auto", height: "48px", marginRight: "10px" }} />
              <span style={{ margin: 0 }}>Superior Room - 1 double bed or 2 twin beds.</span>
            </div>
            <div className='d-flex align-items-center'>
              <p style={{ margin: 0, marginRight: "10px" }}>
                <FaIndianRupeeSign />1200/<small>night</small>
              </p>
              <button
                className="btn"
                style={{
                  borderRadius: "10px",
                  backgroundColor: "Black",
                  color: "#FFFFFF",
                }}
              >
                Book now
              </button>
            </div>
          </div>
        </div>
        <hr className='my-4' />

        <div className='col-lg-12 mb-4'>
          <h3 className='mt-2'>You May Also Like</h3>
          <PopularDestination />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DetailTopDestination;
