import React, { useState } from "react";
import contactt from "../../Assets/Images/watt.jpeg";
import Nav from "../NavBar/Nav";
import Footer from "../Footer/Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FadeLoader } from "react-spinners";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    PhoneNumber: "",
    Message: ""
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));

    // Validate phone number
    if (id === "PhoneNumber") {
      const phoneRegex = /^\d{10}$/;
      const isValidPhone = phoneRegex.test(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        PhoneNumber: isValidPhone ? "" : "Phone number must have 10 digits"
      }));
    }

    // Validate email
    if (id === "Email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        Email: isValidEmail ? "" : "Invalid email address"
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for validation errors before submitting
    if (Object.values(errors).some((error) => error)) {
      toast.error("Please fix the validation errors.", {
        autoClose: 3000, // Adjust the duration to 3 seconds
      });
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}ContactUs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Name: formData.Name,
          Email: formData.Email,
          PhoneNumber: formData.PhoneNumber,
          Message: formData.Message
        })
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        toast.success("Message sent successfully!", {
          autoClose: 3000, // Adjust the duration to 3 seconds
        });
        setFormData({
          Name: "",
          Email: "",
          PhoneNumber: "",
          Message: ""
        });
      } else {
        toast.error(data.message || "Failed to send message.", {
          autoClose: 3000, // Adjust the duration to 3 seconds
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      toast.error("An error occurred while sending the message.", {
        autoClose: 3000, // Adjust the duration to 3 seconds
      });
    }
  };

  return (
    <>
      <Nav />
      <ToastContainer />
      <div
        className="contact-page"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="contact-container"
          style={{
            backgroundImage: `url(${contactt})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            flex: "1 0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div className="container mt-5 mb-5">
            <div className="row justify-content-start">
              <div className="col-lg-4">
                <h3 className="text-center" style={{color:"white"}}>Get In Touch With Us</h3>
                <small className="text-center d-block mb-3" style={{color:"white"}} >
                  You can mail us at cs@myexclusivedeals.com <br />
                  or drop in a message below & we will get back to you in a jiffy.
                </small>
                <form onSubmit={handleSubmit}>
                  <div className="form-group mt-4" style={{ marginBottom: "15px" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="Name"
                      placeholder="Name"
                      value={formData.Name}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        color: "#fff",
                      }}
                    />
                  </div>
                  {errors.Name && <div className="text-danger">{errors.Name}</div>}
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <input
                      type="email"
                      className="form-control"
                      id="Email"
                      placeholder="Email"
                      value={formData.Email}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        color: "#fff",
                      }}
                    />
                  </div>
                  {errors.Email && <div className="text-danger">{errors.Email}</div>}
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <input
                      type="tel"
                      className="form-control"
                      id="PhoneNumber"
                      placeholder="Phone Number"
                      value={formData.PhoneNumber}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        color: "#fff",
                      }}
                    />
                  </div>
                  {errors.PhoneNumber && <div className="text-danger">{errors.PhoneNumber}</div>}
                  <div className="form-group" style={{ marginBottom: "15px" }}>
                    <textarea
                      className="form-control"
                      id="Message"
                      rows="5"
                      placeholder="Write your message here"
                      value={formData.Message}
                      onChange={handleChange}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.2)",
                        color: "#fff",
                      }}
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#343a40",
                        borderColor: "#343a40",
                        width: "100%"
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <FadeLoader
                          color="#36d7b7"
                          height={10}
                          margin={2}
                          radius={2}
                          speedMultiplier={1.5}
                          width={2}
                        />
                      ) : (
                        "Send Message"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer style={{ flexShrink: 0 }} />
      </div>
    </>
  );
};

export default ContactUs;
