import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHotel, faPlane } from "@fortawesome/free-solid-svg-icons";

const TicketBox = () => {
  return (
    <div className="p-4 glass-panel">
      <div className="row">
        <div style={{ color: "white" }} className="d-flex align-items-center">
          <h3 className="d-flex align-items-center me-3">
            {" "}
            <div style={{ marginRight: "10px" }}>
              <FontAwesomeIcon icon={faPlane} />
            </div>
            <div>
              <h6 style={{ margin: 0 }}>
                <b>Flights</b>
              </h6>
            </div>
          </h3>
          
          <h3 className="d-flex align-items-center ps-3" style={{borderLeft:"2px solid white"}}>
            {" "}
            <div style={{ marginRight: "10px" }}>
              <FontAwesomeIcon icon={faHotel} />
            </div>
            <div>
              <h6 style={{ margin: 0 }}>
                <b>Hotel</b>
              </h6>
            </div>
          </h3>
        </div>
      </div>

      <div className="d-flex gap-2 align-items-center flex-wrap">
        <div>
          {/* Field 1 */}
          <div
            className="form-group text-start mb-3 "
            style={{ color: "#FFFFFF" }}
          >
            <label htmlFor="From" style={{ fontWeight: '600' }}>From</label>
            <input type="text" id="From" className="form-control glass-panel px-3 py-2 m-0" style={{border:"1px solid white"}} />
          </div>
        </div>
        <div>
          {/* Field 2 */}
          <div
            className="form-group text-start mb-3"
            style={{ color: "#FFFFFF" }}
          >
            <label htmlFor="To" style={{ fontWeight: '600' }}>To</label>
            <input type="text" id="To" className="form-control glass-panel px-3 py-2 m-0" style={{border:"1px solid white"}}/>
          </div>
        </div>
        <div>
          {/* Field 3 */}
          <div
            className="form-group text-start mb-3"
            style={{ color: "#FFFFFF" }}
          >
            <label htmlFor="Trip" style={{ fontWeight: '600' }}>Trip</label>
            <input type="text" id="Trip" className="form-control glass-panel px-3 py-2 m-0" style={{border:"1px solid white"}}/>
          </div>
        </div>
        <div>
          {/* Field 4 */}
          <div
            className="form-group text-start mb-3 "
            style={{ color: "#FFFFFF" }}
          >
            <label htmlFor="Depart-Return" style={{ fontWeight: '600' }}>Depart-Return</label>

            <input type="text" id="Depart-Return" className="form-control glass-panel px-3 py-2 m-0" style={{border:"1px solid white"}}/>
          </div>
        </div>

        <div>
          {/* Field 4 */}
          <button
            className="btn mt-2"
            style={{
              borderRadius: "10px",
              backgroundColor: "Black",
              color: "#FFFFFF",
            }}
          >
            Show Flights
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketBox;
