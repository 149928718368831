import React, { useState, useEffect } from "react";
import newYork from "../../Assets/Images/NewYork.svg"; // This will be used as a placeholder if needed
import { Link, useNavigate } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6";

const TopDestinations = () => {
  const [destinations, setDestinations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}TopDestination`); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        console.log(result);
        if (result.status === "true") {
          setDestinations(result.data);
        } else {
          console.error("Failed to fetch destinations: ", result);
        }
      } catch (error) {
        console.error("Error fetching destinations:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = (id) => {
    sessionStorage.setItem('selectedDestinationId', id);
    navigate('/DetailTopDestination');
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3>Top Destinations</h3>
          <p>Lorem ipsum hen ajfi huie hfhu ajsj eis ejif fjij</p>
        </div>
        <div>
          <Link to="/MainTopDestinations">
            <button
              style={{
                backgroundColor: "#000000",
                color: "#FFFFFF",
                borderRadius: "5px",
              }}
            >
              See All
            </button>
          </Link>
        </div>
      </div>

      <div>
        <div className="row mt-4 gap-4 gap-md-0">
          {destinations.slice(0, 4).map((destination) => (
            <div
              className="col-md-3 col-sm-6 ps-0"
              key={destination.Id}
              onClick={() => handleClick(destination.Id)}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "400px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={destination.Image || newYork}
                  alt={destination.Title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <div
                  className="overlay-text"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: "20px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <h4>{destination.Title}</h4>
                    <h4><FaIndianRupeeSign/>{destination.Price}</h4>
                  </div>
                  <p>{destination.Description}</p>
                  <br />
                  <button className="glass-panel m-0"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid white",
                      borderRadius: "5px",
                      color: "white",
                      padding: "5px 10px",
                    }}
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopDestinations;
