// import React, { useState } from "react";
// import LeftImage from "../../Assets/Images/Auth.svg";
// import { Link } from "react-router-dom";
// import Logo from "../../Assets/Images/Group.svg";

// function PasswordReset() {
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//     confirmPassword: "", // New field for confirming the password
//   });

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [passwordsMatch, setPasswordsMatch] = useState(true);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));

//     if (name === "confirmPassword") {
//       setPasswordsMatch(formData.password === value);
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Check if passwords match
//     if (formData.password !== formData.confirmPassword) {
//       setPasswordsMatch(false);
//       return;
//     }

//     // Your form submission logic goes here
//     console.log("Form submitted:", formData);
//   };

//   return (
//     <>
//       <div className="container-fluid" style={{ overflow: "hidden" }}>
//         <div className="row">
//           <div className="col-lg-6 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover",
//                 height: "100vh",
//               }}
//             ></div>
//           </div>

//           {/* LogIn Body */}

//           <div
//             className="col-lg-6 "
//             style={{ backgroundColor: "white", height: "100vh" }}
//           >
//             {/* putting Logo */}
//             <div>
//               <img src={Logo} alt="Logo" />
//             </div>

//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2"
//                 style={{
//                   maxHeight: "550px",
//                   borderRadius: "20px",
//                   border: "none",
//                 }}
//               >
//                 <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
//                   <h2
//                     className="card-title justify-content-center mb-4"
//                     style={{ color: "black" }}
//                   >
//                     Set A Password
//                   </h2>
//                   <p>Please set a new password for your account.</p>

//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <div className="input-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           name="password"
//                           placeholder="New Password"
//                           value={formData.password}
//                           onChange={handleChange}
//                           className="form-control"
//                           required
//                         />
//                         <button
//                           type="button"
//                           className="btn btn-outline-secondary"
//                           onClick={togglePasswordVisibility}
//                           style={{ color: "black" }}
//                         >
//                           {showPassword ? "Hide" : "Show"}
//                         </button>
//                       </div>
//                     </div>
//                     <div className="mb-3">
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           name="confirmPassword"
//                           placeholder="Confirm Password"
//                           value={formData.confirmPassword}
//                           onChange={handleChange}
//                           className="form-control"
//                           required
//                         />
//                         <button
//                           type="button"
//                           className="btn btn-outline-secondary"
//                           onClick={toggleConfirmPasswordVisibility}
//                           style={{ color: "black" }}
//                         >
//                           {showConfirmPassword ? "Hide" : "Show"}
//                         </button>
//                       </div>
//                       {!passwordsMatch && (
//                         <small className="text-danger">Passwords do not match</small>
//                       )}
//                     </div>
//                     <div className="text-center mt-3">
//                       <button
//                         type="submit"
//                         className="btn mt-1"
//                         style={{
//                           borderRadius: "10px",
//                           backgroundColor: "black",
//                           color: "white",
//                           width: "100%", // Set the width to 100%
//                         }}
//                       >
//                         Reset Password
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default PasswordReset;

// import React, { useState,u } from "react";
// import LeftImage from "../../Assets/Images/Auth.svg";
// import Logo from "../../Assets/Images/Group.svg";

// function PasswordReset() {
//   const [formData, setFormData] = useState({
//     password: "",
//     confirmPassword: "",
//   });

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [passwordsMatch, setPasswordsMatch] = useState(true);
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };
   
//   use

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));

//     if (name === "confirmPassword") {
//       setPasswordsMatch(formData.password === value);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // Check if passwords match
//     if (formData.password !== formData.confirmPassword) {
//       setPasswordsMatch(false);
//       return;
//     }

//     try {
//       const response = await fetch("http://localhost/NomadicTrailz-Backend/public/api/PasswordChange", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization":`Bearer ${token}`
//         },
//         body: JSON.stringify({
//           Password: formData.password,
//         }),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to change password");
//       }

//       const data = await response.json();
//       console.log("Password changed:", data);
//       setSuccess("Password changed successfully");
//       setError("");
//     } catch (error) {
//       console.error("Error changing password:", error);
//       setError("Failed to change password. Please try again later.");
//       setSuccess("");
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid" style={{ overflow: "hidden" }}>
//         <div className="row">
//           <div className="col-lg-6 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover",
//                 height: "100vh",
//               }}
//             ></div>
//           </div>

//           {/* Right Side */}
//           <div className="col-lg-6" style={{ backgroundColor: "white", height: "100vh" }}>
//             {/* Logo */}
//             <div>
//               <img src={Logo} alt="Logo" />
//             </div>

//             {/* Card Body */}
//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div className="card mx-2" style={{ border: "none" }}>
//                 <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
//                   <h2 className="card-title justify-content-center mb-4" style={{ color: "black" }}>
//                     Set A Password
//                   </h2>
//                   <p>Please set a new password for your account.</p>

//                   <form onSubmit={handleSubmit}>
//                     {/* Input fields for password and confirm password */}
//                     <div className="mb-3">
//                       <div className="input-group">
//                         <input
//                           type={showPassword ? "text" : "password"}
//                           name="password"
//                           placeholder="New Password"
//                           value={formData.password}
//                           onChange={handleChange}
//                           className="form-control"
//                           required
//                         />
//                         <button
//                           type="button"
//                           className="btn btn-outline-secondary"
//                           onClick={togglePasswordVisibility}
//                           style={{ color: "black" }}
//                         >
//                           {showPassword ? "Hide" : "Show"}
//                         </button>
//                       </div>
//                     </div>
//                     <div className="mb-3">
//                       <div className="input-group">
//                         <input
//                           type={showConfirmPassword ? "text" : "password"}
//                           name="confirmPassword"
//                           placeholder="Confirm Password"
//                           value={formData.confirmPassword}
//                           onChange={handleChange}
//                           className="form-control"
//                           required
//                         />
//                         <button
//                           type="button"
//                           className="btn btn-outline-secondary"
//                           onClick={toggleConfirmPasswordVisibility}
//                           style={{ color: "black" }}
//                         >
//                           {showConfirmPassword ? "Hide" : "Show"}
//                         </button>
//                       </div>
//                       {!passwordsMatch && (
//                         <small className="text-danger">Passwords do not match</small>
//                       )}
//                     </div>
//                     {error && <p className="text-danger">{error}</p>}
//                     {success && <p className="text-success">{success}</p>}
//                     <div className="text-center mt-3">
//                       <button
//                         type="submit"
//                         className="btn mt-1"
//                         style={{ borderRadius: "10px", backgroundColor: "black", color: "white", width: "100%" }}
//                       >
//                         Reset Password
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default PasswordReset;


import React, { useState, useEffect } from "react";
import LeftImage from "../../Assets/Images/Auth.svg";
import Logo from "../../Assets/Images/Group.svg";

function PasswordReset() {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [token, setToken] = useState(""); // State for storing the token

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


    const token = sessionStorage.getItem("token");
    console.log(
      "tkn",token
    )


  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "confirmPassword") {
      setPasswordsMatch(formData.password === value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    try {
      const response = await fetch("http://localhost/NomadicTrailz-Backend/public/api/PasswordChange", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify({
          Password: formData.password,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to change password");
      }

      const data = await response.json();
      console.log("Password changed:", data);
      setSuccess("Password changed successfully");
      setError("");
    } catch (error) {
      console.error("Error changing password:", error);
      setError("Failed to change password. Please try again later.");
      setSuccess("");
    }
  };

 

  return (
    <>
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100"
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </div>

          {/* Right Side */}
          <div className="col-lg-6" style={{ backgroundColor: "white", height: "100vh" }}>
            {/* Logo */}
            <div>
              <img src={Logo} alt="Logo" />
            </div>

            {/* Card Body */}
            <div className="h-100 d-flex align-items-center justify-content-center">
              <div className="card mx-2" style={{ border: "none" }}>
                <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
                  <h2 className="card-title justify-content-center mb-4" style={{ color: "black" }}>
                    Set A Password
                  </h2>
                  <p>Please set a new password for your account.</p>

                  <form onSubmit={handleSubmit}>
                    {/* Input fields for password and confirm password */}
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="New Password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                          style={{ color: "black" }}
                        >
                          {showPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{ color: "black" }}
                        >
                          {showConfirmPassword ? "Hide" : "Show"}
                        </button>
                      </div>
                      {!passwordsMatch && (
                        <small className="text-danger">Passwords do not match</small>
                      )}
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    {success && <p className="text-success">{success}</p>}
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{ borderRadius: "10px", backgroundColor: "black", color: "white", width: "100%" }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordReset;
