// import React, { useState } from "react";
// import LeftImage from "../../Assets/Images/Auth.svg";
// import { Link } from "react-router-dom";
// import Logo from "../../Assets/Images/Group.svg";

// function ForgetPassword() {
//   const [formData, setFormData] = useState({
//     email: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Your form submission logic goes here
//     console.log("Form submitted:", formData);
//   };

//   return (
//     <>
//       <div className="container-fluid" style={{ overflow: "hidden" }}>
//         <div className="row">
//           <div className="col-lg-6 p-0 d-none d-lg-block">
//             <div
//               className="w-100 h-100"
//               style={{
//                 backgroundImage: `url(${LeftImage})`,
//                 backgroundSize: "cover",
//                 height: "100vh",
//               }}
//             ></div>
//           </div>

//           {/* LogIn Body */}

//           <div
//             className="col-lg-6 "
//             style={{ backgroundColor: "white", height: "100vh" }}
//           >
//             {/* putting Logo */}
//             <div>
//               <img src={Logo} alt="Logo" />
//             </div>

//             <div className="h-100 d-flex align-items-center justify-content-center">
//               <div
//                 className="card mx-2"
//                 style={{
//                   maxHeight: "550px",
//                   borderRadius: "20px",
//                   border: "none",
//                 }}
//               >
//                 <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
//                   <h2
//                     className="card-title justify-content-center mb-4"
//                     style={{ color: "black" }}
//                   >
//                     FORGET YOUR PASSWORD
//                   </h2>
//                   <h6>
//                     Don’t worry, happens to all of us. Enter your email below to
//                     recover your password
//                   </h6>

//                   <form onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <input
//                         type="email"
//                         name="email"
//                         style={{ width: "100%" }}
//                         placeholder="Email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="form-control"
//                         required
//                       />
//                     </div>
//                     <div className="text-center mt-3">
//                       <button
//                         type="submit"
//                         className="btn mt-1"
//                         style={{
//                           borderRadius: "10px",
//                           backgroundColor: "black",
//                           color: "white",
//                           width: "100%", // Set the width to 100%
//                         }}
//                       >
//                         Submit
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ForgetPassword;




import React, { useState } from "react";
import LeftImage from "../../Assets/Images/Auth.svg";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/Group.svg";
import { useNavigate } from 'react-router-dom';

function ForgetPassword() {
  const [formData, setFormData] = useState({
    Email: "",
  });
  const [error, setError] = useState(""); // Define state for error handling
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}PasswordReset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to reset password. Please try again later.');
      }
  
      const data = await response.json();
      console.log("Form submitted:", data);
      sessionStorage.setItem("token1",data.token);
      sessionStorage.setItem("UserValid",data.UserValid);
      navigate('/VerifyOtpForget');
      // Handle success, redirect to verify OTP page or show success message
  
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message to the user
      setError('Failed to reset password. Please try again later.');
    }
  };
  

  return (
    <>
      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 p-0 d-none d-lg-block">
            <div
              className="w-100 h-100"
              style={{
                backgroundImage: `url(${LeftImage})`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            ></div>
          </div>

          <div
            className="col-lg-6 "
            style={{ backgroundColor: "white", height: "100vh" }}
          >
            <div>
              <img src={Logo} alt="Logo" />
            </div>

            <div className="h-100 d-flex align-items-center justify-content-center">
              <div
                className="card mx-2"
                style={{
                  maxHeight: "550px",
                  borderRadius: "20px",
                  border: "none",
                }}
              >
                <div className="card-body p-5 py-3 d-flex flex-column align-items-center justify-content-center">
                  <h2
                    className="card-title justify-content-center mb-4"
                    style={{ color: "black" }}
                  >
                    FORGET YOUR PASSWORD
                  </h2>
                  <h6>
                    Don’t worry, happens to all of us. Enter your email below to
                    recover your password
                  </h6>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <input
                        type="email"
                        name="Email"
                        style={{ width: "100%" }}
                        placeholder="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="text-center mt-3">
                      <button
                        type="submit"
                        className="btn mt-1"
                        style={{
                          borderRadius: "10px",
                          backgroundColor: "black",
                          color: "white",
                          width: "100%", // Set the width to 100%
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
